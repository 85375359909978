<template>
  <!-- 管理员管理 -->
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>
<!--        <div class="header-search">-->
<!--            <el-form ref="form" inline :model="searchData" label-width="">-->
<!--                <el-form-item label="分类名称">-->
<!--                    <el-input style="width:250px" v-model="searchData.name" size="small"-->
<!--                              placeholder="请输入"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label=" ">-->
<!--                    <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>-->
<!--                    <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>-->
<!--                </el-form-item>-->
<!--            </el-form>-->
<!--        </div>-->
        <el-button style="margin-bottom:20px;" type="primary" icon="el-icon-plus" size="small" @click="goAdd"> 添加
        </el-button>
        <tp-table
            :isNeedSerialNumber="true"
            :tableData="list"
            :columns="columns"
            :totalNum="total"
            :current-page.sync="currentPage"
            :pageSize.sync="page_size"
        />
    </div>
</template>

<script>
import {getList, changeStatus, removeCategory} from './api'

import {authBtnMixin} from '@/mixins/authBtnMixin'

const columns = [
    {
        label: '名称',
        prop: 'title',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['title']}</p>
        }
    },
    {
        label: '排序值',
        prop: 'sort',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['sort']}</p>
        }
    },
    {
        label: '创建时间',
        minWidth: '160',
        prop: 'created_at',
        customRender(h, row) {
            return (
                <p class="one-line" title={row['created_at']}>
                    {row['created_at']}
                </p>
            )
        }
    },
    {
        label: '操作',
        fixed: 'right',
        width: '200',
        customRender(h, row) {
            return (
                <div>
                    <el-link style="margin-right:10px;" type="primary" onClick={() => this.goEdit(row['id'])}>
                        编辑
                    </el-link>
                    <el-link type="danger" onClick={() => this.goRemove(row['id'])}>
                        删除
                    </el-link>
                </div>
            )
        }
    }
]

export default {
    name: 'Index',
    mixins: [authBtnMixin],
    data() {
        return {
            dialogVisible: false,
            isShow: false,
            list: [],
            page_size: 10,
            searchData: {
                name: '',
                mobile: ''
                // roleId: ''
            },
            total: 0,
            currentPage: 1,
            columns,
            roleList: []
        }
    },
    provide() {
        return {
            context: this
        }
    },
    watch: {
        currentPage() {
            this.getList()
        },
        page_size() {
            this.getList()
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        async getList() {
            let params = {
                page: this.currentPage,
                page_size: this.page_size,
                name: this.searchData.name
            }
            const res = await getList(params)
            this.list = res.data || []
            this.total = res.total || 0
        },

        goAdd() {
            this.$router.push({name: 'indexProductAdd'})
        },
        goEdit(id) {
            this.$router.push({name: 'indexProductEdit', params: {id: id || undefined}})
        },
        async goRemove(id) {
            this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    removeCategory({id}).then(() => {
                        this.$message.success('删除成功')
                        this.getList()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },

        reset() {
            Object.assign(this._data.searchData, this.$options.data().searchData)
            this.getList()
        },
        changeStatus(row) {
            const {id, status} = row
            let data = {id, status}
            changeStatus(data).then(() => {
                this.$message.success('操作成功')
                this.getList()
            })
        },
        updatePwd(id) {
            this.dialogVisible = true
        },
        savePwd() {
            this.dialogVisible = false
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
}
</style>
