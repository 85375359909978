import {
    http
} from '@/http/axios.js'


// 列表
export function getList(params) {
    return http({
        url: `/company/indexProductIntro/list`,
        method: 'get',
        params
    })
}


export function removeCategory(params) {
    return http({
        url: `/company/indexProductIntro/del`,
        method: 'post',
        params: params
    })
}
export function addCategory(params) {
    return http({
        url: `/company/indexProductIntro/add`,
        method: 'post',
        params: params
    })
}

export function editCategory(data) {
    return http({
        url: `/company/indexProductIntro/edit`,
        method: 'post',
        data
    })
}

export function detail(id) {
    return http({
        url: `/company/indexProductIntro/detail`,
        method: 'get',
        params: {id : id}
    })
}
